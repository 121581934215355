import request from "@/api/request"

// 企业详情
export function detailInfoPostList (parameter) {
    return request({
      // url: '/job/jobOrgInfo/detailInfoPostList',
      url: '/job/oauth/jobOrgInfo/detailInfoPostList',
      method: 'get',
      params: parameter
    })
  }

//职位详情
export function page (parameter) {
  return request({
    url: '/job/jobPostOrg/page',
    method: 'get',
    params: parameter
  })
}

export function jobPostOrgList (parameter) {
  return request({
    url: '/job/oauth/jobPost/search',
    method: 'get',
    params: parameter
  })
}
