<template>
  <div class="main">
    <Tobs></Tobs>
    <div class="box-title">
      <!-- 背景图 -->
      <div
        class="register-img"
        :style="{ width: fullWidth + 'px', height: fullHeight + 'px' }"
      >
        <div class="img_box" :style="{ width: fullWidth + 'px' }"></div>
      </div>
    </div>
    <div class="content">
      <div class="lfetBox">
        <div class="personInfo">
           <img
              v-if="enterpriseList.logo && enterpriseList.logo != 'null'"
              :src="$imgUrl(enterpriseList.logo)"
              :onerror="$global.srcImgUrl"
              alt=""
              class="photo"
            />
            <div class="cLogo" v-else>
               <span>{{ getFirstWord(enterpriseList.name) }}</span>
            </div>
          <div class="personInfoRight">
            <div class="left">
              <div class="personName">
                <span class="peopleName">{{ enterpriseList.name }}</span>
                <div class="info">
                  {{
                    enterpriseList.properties
                      ? getJobOrgType(enterpriseList.properties)
                      : ""
                  }}
                  <span>|</span>
                  {{ enterpriseList.size ? getGm(enterpriseList.size) : "" }}
                  <span>|</span>
                  {{
                    enterpriseList.orgType
                      ? getTradeType(enterpriseList.orgType)
                      : ""
                  }}
                </div>
              </div>
            </div>

            <div class="right">
              <div class="ReleaseTimeBox">
                <span class="frequency">{{ postLength }}</span>
                <div class="right-position">
                  <span class="position" style="padding-top: 30px">
                    在招职位
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-tabs v-model="activeName">
          <el-tab-pane
            name="first"
            style="padding:0 40px 40px;"
          >
            <span slot="label" style="padding-left: 40px; padding-right: 40px"
              >公司简介</span
            >
            <!-- 详情1 -->
            <div class="projectExperience">
              <div class="listTitle">
                <span class="title">{{ enterpriseList.name }}简介</span>
                <div class="orange"></div>
                <div class="look_all" @click="is_show = !is_show">
                  <p v-show="!is_show" class="projectExperience-intro">
                    {{ enterpriseList.content }}
                  </p>
                  <p class="projectExperience-intro" v-show="is_show">
                    {{ enterpriseList.intro }}
                  </p>
                  <p
                    style="color: #129cffff"
                    v-if="isIntro"
                  >
                    {{ words }}
                  </p>
                </div>
              </div>
            </div>

            <div class="selfEvaluation">
              <div class="listTitle">
                <span>工商信息</span>
                <div class="orange"></div>
              </div>
              <div class="corporateName mb">{{ enterpriseList.name }}简介</div>
              <div class="cor">
                <div class="cor-jj">
                  <p class="cor-left">
                    主要联系人:<span class="cor-right">{{
                      enterpriseList.legallyLiablePerson
                    }}</span>
                  </p>
                  <p style="color: #999; padding-top: 20px">
                    电&emsp;&emsp;话:<span style="color: #666">{{
                      enterpriseList.phone
                        ? enterpriseList.phone.replace(
                            enterpriseList.phone.substring(3, 7),
                            "****"
                          )
                        : ""
                    }}</span>
                  </p>
                  <p
                    style="padding-right: 30px; color: #999; padding-top: 20px"
                  >
                    邮&emsp;&emsp;箱:<span style="color: #666">{{
                      enterpriseList.email
                    }}</span>
                  </p>
                </div>

                <div class="cor-mb">
                  <p class="cor-left">
                    单位性质:<span class="cor-right">{{
                      enterpriseList.properties
                        ? getJobOrgType(enterpriseList.properties)
                        : ""
                    }}</span>
                  </p>
                  <!-- <p class="cor-left">
                    经营状态:<span class="cor-right"></span>
                  </p> -->
                  <p class="cor-left">
                    单位地址:<span class="cor-right">{{
                      enterpriseList.address
                    }}</span>
                  </p>
                  <!-- <p class="cor-left">
                    统一社会信用代码:<span class="cor-right">{{
                      $occlusionToPhone(enterpriseList.orgCode)
                    }}</span>
                  </p> -->
                </div>
              </div>
            </div>

            <div class="skillCertificate">
              <div class="listTitle"></div>
            </div>

            <div class="location" v-if="enterpriseList.position">
              <div class="listTitle">
                <span>公司地址</span>
                <div class="orange"></div>
              </div>
              <div class="locationBox">
                <div id="container">
                  <el-amap class="amap-box" :vid="'amap-vue'" :center="enterpriseList.position">
                    <el-amap-marker :position="enterpriseList.position"></el-amap-marker>
                    <el-amap-text :text="enterpriseList.address" :offset = offset ></el-amap-text>
                  </el-amap>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <!-- 在招职位 -->
          <el-tab-pane name="second">
            <span slot="label" style="padding-left: 40px; padding-right: 40px"
              >在招职位({{ postLength }})</span
            >
            <div class="workType">
              <span class="leftSpan">筛选</span>
              <!-- tab栏 -->
              <div class="filter-select-box">
                <div class="dropdown-wrap">
                  <el-cascader
                    class="dropdown-select"
                    v-model="postTypeId"
                    :options="postTypeList"
                    :show-all-levels="false"
                    :props="typeProps"
                    @change="choosePostType"
                    placeholder="职位类型"
                  ></el-cascader>
                  <el-select
                    v-model="selectExp"
                    @change="chooseSelectExp"
                    placeholder="经验要求"
                    class="dropdown-select"
                  >
                    <el-option
                      v-for="item in jobExp"
                      :key="item.name"
                      :label="item.name"
                      :value="item.code"
                      class="ipt"
                    >
                    </el-option>
                  </el-select>

                  <el-select
                    v-model="selectEdu"
                    @change="chooseSelectEdu"
                    placeholder="学历要求"
                    class="dropdown-select"
                  >
                    <el-option
                      v-for="item in jobEdu"
                      :key="item.name"
                      :label="item.name"
                      :value="item.code"
                      class="ipt"
                    >
                    </el-option>
                  </el-select>

                  <el-select
                    v-model="selectPay"
                    @change="chooseSelectPay"
                    placeholder="薪资待遇"
                    class="dropdown-select"
                  >
                    <el-option
                      v-for="item in jobPay"
                      :key="item.name"
                      :label="item.name"
                      :value="item.code"
                      class="ipt"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="enterprise">
              <div class="enterprise-box">
                <div class="enterprise-left">
                  <div
                    class="enterprise-list"
                    v-for="(item, index) in positionList"
                    :key="index"
                  >
                    <div class="enterprise-content">
                      <p class="enterprise-post">{{ item.name }}</p>

                      <div style="color: #fff">
                        <el-button
                          type="primary"
                          class="post"
                          @click.stop="toDatum(item, id)"
                          >申请岗位</el-button
                        >
                      </div>
                    </div>

                    <span class="pay">{{
                      item.pay ? getPay(item.pay) : ""
                    }}</span>

                    <span class="cont" v-if="item.cityName">{{ item.cityName }}|</span>
                    <span class="cont" v-else>{{ item.city ? $dictInfo.getCityForId(item.city): "" }}|</span>
                    <span style="font-size: 14px; padding-top: 10px"
                      >{{ item.exp ? getPostExp(item.exp) : "" }}|</span
                    >
                    <span style="font-size: 14px; padding-top: 10px">{{
                      item.edu ? getPostEdu(item.edu) : ""
                    }}</span>

                    <div class="enterprise-time">
                      <p class="time">
                        {{
                          item.publishTime ? getPartTime(item.publishTime) : ""
                        }}
                      </p>
                    </div>
                  </div>
                  <el-empty v-if="total == 0" description="空空如也"></el-empty>
                </div>
              </div>
            </div>
            <div style="text-align: center; background-color: #eff1f4">
              <el-pagination
                :background="true"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <Foots></Foots>
  </div>
</template>

<script>
import Tobs from "@/components/hometitle/hometitle.vue";
import Foots from "@/components/foot/foot.vue";
import { getCity } from '@/api/index/index'
import {
  detailInfoPostList,
  jobPostOrgList,
} from "@/api/enterpriseDetails/index";
import { getDict } from "@/api/system/system";
import { jobPostType } from "@/api/home/home";

export default {
  // name: "WebPortalEnterpriseDetails",
  components: {
    Tobs,
    Foots,
  },
  data() {
    return {
      srcImgUrl : require('@/assets/home/affiliated-img.png'),
      offset :[0,-50],
      total: 1,
      jobOrgId: "", //企业详情ID
      publishStatus: 2, //状态
      imgUrl: "",
      activeName: "first",
      enterpriseList: "",
      postLength: 0,
      is_show: false,
      isIntro: false,
      isShow: true,
      dict: [],
      orgType: [],
      jobEdu: [],
      jobPay: [],
      jobExp: [],
      postTypeList: [], //职位类型列表
      positionList: [],
      postTypeId: "", //选择职位类型id
      selectExp: "",
      selectEdu: "",
      selectPay: "",
      params: {
        exp: "", //工作经验
        edu: "", //学历
        pay: "", //薪资
        jobOrgId: "",
        pageNo: 1,
        pageSize: 10,
      },
      typeProps: {
        value: "id",
        label: "title",
        children: "children",
      },
      fullWidth: document.documentElement.clientWidth,
      fullHeight: document.documentElement.clientHeight,
    };
  },

  created() {
    this.jobOrgId = this.$route.query.id;
    this._getDict();
    this.getDetail();
    this.getOrg();
    this.getPosition();
    this.showCityList = JSON.parse(localStorage.getItem('cityList'))
    this.getJobPostType();
    this.getCityList()
    if(localStorage.getItem("readInfo")){
      this.readInfo = JSON.parse(localStorage.getItem("readInfo"))
    }
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {},

  computed: {
    words() {
      if (this.is_show === false) {
        return "展开全部";
      } else if (this.is_show === true) {
        return "收起全部";
      } else if (this.is_show === "") {
        return null;
      } else {
        return null;
      }
    },
  },

  methods: {
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth;
      this.fullHeight = document.documentElement.clientHeight;
    },
    // 企业详情
    getDetail() {
      detailInfoPostList({ id: this.jobOrgId }).then((res) => {
        this.enterpriseList = res.data;
        if (this.enterpriseList.intro.length > 200) {
          this.enterpriseList.content = this.enterpriseList.intro.substring(0, 200) + "...";
          this.isIntro = true;
        }else{
          this.enterpriseList.content = this.enterpriseList.intro;

        }
         this.enterpriseList.position = [this.enterpriseList.longitude,this.enterpriseList.latitude]
        this.postLength = res.data.postList ? res.data.postList.length : 0;
      });
    },

    //岗位详情
    getPosition() {
      this.params.jobOrgId = this.jobOrgId;
      this.params.publishStatus = this.publishStatus;
      jobPostOrgList(this.params).then((res) => {
        this.positionList = res.data.rows;
        this.total = res.data.totalRows;
      });
    },

    //行业
    async getOrg() {
      let params = {
        code: "trade_type",
      };
      let res = await jobPostType(params);
      this.orgList = this.hasChildren(res.data[0].children);
    },

    // 回显城市
    getCityList() {
      getCity(this.cityParams).then(res => {
        this.cityList = []

        this.cityList = res.data
      })
    },
    //岗位详情
    toDatum(item) {
      this.$router.push({
	        name: "datum",
	        query:{
	          id: item.id
	        }
        });
    },

    // 职位类型
    getJobPostType() {
      jobPostType({ code: "post_type" }).then((res) => {
        this.postTypeList = this.hasChildrenList(res.data[0].children);
        this.postTypeList.unshift({ title: "全部", id: "all" });
      });
    },

    getPartTime(val) {
      var timearr = val.replace(" ", ":").replace(/:/g, "-").split("-");
      var timestr =
        "" + Number(timearr[0]) + "-" + timearr[1] + "-" + timearr[2];
      return timestr;
    },
    _getDict() {
      getDict({}).then((res) => {
        if (res.code == 200 && res.message === "请求成功") {
          this.dict = res.data;
          localStorage.setItem("dict", JSON.stringify(res.data));
          this.job_gm = this.dict.filter((i) => i.code == "job_gm")[0].children;
          this.orgType = this.dict.filter(
            (i) => i.code == "job_org_type"
          )[0].children;
          this.jobEdu = this.dict.filter(
            (i) => i.code == "job_edu"
          )[0].children;
          this.jobEdu.unshift({ name: "全部", code: "all" });
          this.jobPay = this.dict.filter(
            (i) => i.code == "job_pay"
          )[0].children;
          this.jobPay.unshift({ name: "全部", code: "all" });
          this.jobExp = this.dict.filter(
            (i) => i.code == "job_exp"
          )[0].children;
          this.jobExp.unshift({ name: "全部", code: "all" });
        }
      });
    },

    imgInfo(code) {
      return this.imgUrl + code;
    },

    getJobOrgType(code) {
      return this.orgType.filter((i) => i.code == code)[0].name;
    },
    getGm(code) {
      return this.job_gm.filter((i) => i.code == code)[0].name;
    },

    getPay(code) {
      if(this.jobPay.length >0 && code){
        return this.jobPay.filter((i) => i.code == code)[0].name;
      }else{
        return ''
      }

    },

    getPostExp(code) {
      if(this.jobExp.length >0 && code){
         return this.jobExp.filter((i) => i.code == code)[0].name;
      }else{
        return ''
      }

    },

    getPostEdu(id) {
      if(this.jobEdu.length >0 && id){
         return this.jobEdu.filter((i) => i.code == id)[0].name;
      }else{
        return ''
      }
    },

    // 显示工作经验
    getExp(val) {
      return this.exp.filter((i) => i.code == val)[0].name;
    },

    // 显示学历
    showEdu(val) {
      return this.jobEdu.filter((i) => i.code == val)[0].name;
    },
    // 添加对应字段
    hasChildrenList(list) {
      list.forEach((item) => {
        if (item.children.length > 0) {
          this.hasChildrenList(item.children);
        } else {
          item.children = null;
        }
      });
      return list;
    },
    // 添加对应字段
    hasChildren(list) {
      list.forEach((item) => {
        item.name = item.title;
        item.child = item.children;
        item.selected = false;
        if (item.child.length > 0) {
          this.hasChildren(item.child);
        } else {
          item.child = [];
        }
      });
      return list;
    },
    getTradeType(str) {
      let newStr = str.split(',')
      let arr = []
      if (this.orgList.length > 0) {
        newStr.forEach(i => {
          let ids = this.getOrgTypeForTreer(this.orgList, i)
          if (ids.length > 0) {
            arr.push(ids)
          }
        })
      }
      let strs = arr.join('，')
      return strs
    },
     // 根据公司行业id回显  递归找父级id
    getOrgTypeForTreer(list, id) {
      var ids = []
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          ids.push(list[i].title)
          break
        } else if (list[i].children && list[i].children.length > 0) {
          ids = this.getOrgTypeForTreer(list[i].children, id)
          if (ids.length > 0) return ids
        }
      }
      return ids
    },
    // 职位类型
    choosePostType(val) {
      if (val[0] !== "all") {
        this.postTypeId = val[val.length - 1];
        this.params.positionType = this.postTypeId;
      } else {
        this.params.positionType = "";
      }
      this.getPosition();
    },
    chooseSelectExp() {
      if (this.selectExp !== "all") {
        this.params.exp = this.selectExp;
      } else {
        this.params.exp = "";
      }
      this.getPosition();
    },
    chooseSelectEdu() {
      if (this.selectEdu !== "all") {
        this.params.edu = this.selectEdu;
      } else {
        this.params.edu = "";
      }
      this.getPosition();
    },
    chooseSelectPay() {
      if (this.selectPay !== "all") {
        this.params.pay = this.selectPay;
      } else {
        this.params.pay = "";
      }
      this.getPosition();
    },
    handleCurrentChange(val) {
      this.params.pageNo = val;
      this.getPosition();
    },
    getFirstWord(name) {
      if (!name) {
        return
      }
      let pinyin = require('js-pinyin')
      pinyin.setOptions({ checkPolyphone: false, charCase: 0 })
      let word = pinyin.getFullChars(name).substring(0, 1)
      return word
    },
  },
};
</script>

<style lang="scss" scoped>
.register-img {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  .img_box {
    position: absolute;
    background-image: url("../../assets/jobInformation/bgc.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 101%;
    height: 30%;
  }
}
.content {
  padding-top: 180px;
  margin-bottom: 100px;
}

.mb {
  margin-bottom: 10px;
}
.divider {
  padding: 0 30px;
  .el-divider {
    margin: 0;
  }
}

.headImg {
  width: 100%;
  height: 220px;
  object-fit: cover;
  margin-top: 80px;
}
.listTitle {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  span {
    font-size: 18px;
    font-weight: bold;
  }
  .orange {
    width: 40px;
    height: 4px;
    background-color: #ff8d19;
    margin-top: 6px;
  }
  .look_all {
    text-indent: 2em;
    .projectExperience-intro {
      color: #666;
    }
  }
}

.lfetBox {
  width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  padding: 10px 0 0 0;
  .personInfo {
    padding: 30px;
    display: flex;
    justify-content: space-between;
    .photo {
      width: 100px;
      height: 100px;
      border-radius: 5px;
    }
    .cLogo {
      width: 100px;
      height: 100px;
      text-align: center;
      line-height: 100px;
      border: 2px solid #ccc;
      background-color: #f4f4f4;
      span {
        font-size: 24px;
        color: #ccc;
        font-weight: bolder;
      }
    }
    .personAvatar {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }
    .personInfoRight {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-left: 30px;
      .left {
            width: 80%;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        .personName {
          margin-top: 10px;
          .peopleName {
            margin-right: 10px;
            font-size: 28px;
            font-weight: bold;
          }
          .info {
            padding-top: 15px;
            color: #333;
            font-size: 14px;
          }
        }
        .personSexBox {
          margin-bottom: 20px;
        }
      }
      .right {
        .ReleaseTimeBox {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 30px;
          .frequency {
            font-size: 30px;
            font-weight: bold;
            padding-top: 20px;
          }
          .right-position {
            margin-top: 20px;
            color: #333;
            .position {
              font-size: 14px;
            }
          }
        }

        .contact {
          width: 140px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #21a3ff;
          color: #fff;
          font-size: 18px;
          border-radius: 4px;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

.listRight {
  width: 282px;
  .seeList,
  .recommend {
    background-color: #fff;
    margin-bottom: 16px;
    padding-bottom: 10px;
    .title {
      height: 48px;
      font-size: 14px;
      color: #029aff;
      line-height: 48px;
      padding: 0 20px 0 24px;
      border-bottom: 1px solid #e8e8e8;
    }
    .peopleList {
      padding: 0 20px 0 24px;
      .peopleItem:last-child {
        border-bottom: none;
      }
      .peopleItem {
        display: flex;
        padding: 10px 0;
        border-bottom: 1px solid #f2f2f2;
        img {
          width: 48px;
          height: 48px;
          margin-right: 20px;
        }
        .peopleInfo {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name {
            display: flex;
            justify-content: space-between;
            span:nth-child(1) {
              font-size: 14px;
            }
            span:nth-child(2) {
              font-size: 12px;
              color: #666;
            }
          }
          .tag {
            font-size: 12px;
          }
        }
      }
    }
  }
  .recommend {
    background-color: #fff;
    .title {
      height: 48px;
      font-size: 14px;
      color: #029aff;
      line-height: 48px;
      padding: 0 20px 0 24px;
      border-bottom: 1px solid #e8e8e8;
    }
  }
}

.projectExperience {
  .projectExperienceItem {
    .corporateName {
      font-size: 18px;
    }
    .position {
    }
    .jobDescription {
      display: flex;
      span {
        font-size: 14px;
      }
      span:nth-child(1) {
        width: 80px;
      }
      span:nth-child(2) {
        flex: 1;
      }
    }
  }
}

.selfEvaluation {
  .cor {
    display: flex;
    border: 2px solid #d8d8d8d8;
    flex-direction: column;
    .cor-jj {
      display: flex;
      justify-content: space-between;
      .cor-left {
        color: #999;
        padding-left: 30px;
        padding-top: 20px;
        .cor-right {
          color: #666;
        }
      }
    }
    .cor-mb {
      display: flex;
      flex-direction: column;
      .cor-left {
        color: #999;
        padding-left: 30px;
        padding-top: 20px;
        .cor-right {
          color: #666;
        }
      }
    }
  }
}

.skillCertificate {
  padding: 0 30px;
  .skillCertificateBox {
    display: flex;
    flex-wrap: wrap;
    .skillCertificateItem {
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      span {
        font-size: 14px;
        color: #666;
      }
      img {
        margin-top: 10px;
        width: 140px;
        height: 88px;
        object-fit: contain;
      }
    }
  }
}

.location {
  .locationBox {
    height: 330px;
    #container {
      // width: 841px;
      // height: 332px;
      // margin-top: 11px;
      width: 100%;
      height: 100%;
    }
  }
}

// 筛选
.workType {
  display: flex;
  padding: 0 40px;
  .leftSpan {
    width: 70px;
    text-align-last: justify;
    position: relative;
    padding-right: 6px;
    color: #999;
    margin: 10px 0;
    font-size: 14px;
  }
  .leftSpan::after {
    position: absolute;
    right: 0;
    content: ":";
  }
}
.workTypeBox {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  .workTypeItem {
    font-size: 14px;
    color: #999;
    text-align: center;
    margin: 10px 25px;
    cursor: pointer;
  }
  .active {
    color: #029aff;
  }
}
.dropdown-select {
  margin-left: 20px;
  width: 120px;
}
.enterprise {
  margin-top: 20px;
  border-radius: 3px;
  background-color: #eff1f4;
  .enterprise-box {
    display: flex;
    flex-direction: column;

    .enterprise-left {
      margin-bottom: 20px;
      .enterprise-list {
        border-radius: 5px;
        background-color: #ffffff;
        margin: 20px 0;
        padding: 0 40px;
      }
      .enterprise-content {
        display: flex;
        justify-content: space-between;
        .enterprise-post {
          font-size: 18px;
          font-weight: bold;
          color: #444;
        }
        .post {
          width: 120px;
          margin-top: 30px;
        }
      }
      .pay {
        font-size: 20px;
        font-weight: 500;
        color: #ff8d19;
      }
      .cont {
        font-size: 14px;
        padding-top: 10px;
        margin-left: 30px;
      }
      .enterprise-time {
        display: flex;
        justify-content: flex-end;
        .time {
          font-size: 14px;
          color: #999;
        }
      }
    }
  }
}
</style>
